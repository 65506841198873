import { getFetcher, postFetcher } from "@network/fetchers";
import {
  GetMaintenanceResponse,
  GetMaintenanceTableRequest,
  GetMaintenanceTableResponse,
  MaintenanceType,
  PostMaintenanceResponse
} from "types/maintenance.types";

function getTable(params?: GetMaintenanceTableRequest) {
  return getFetcher<GetMaintenanceTableResponse>("/maintenance", params);
}

function get(id: number) {
  return getFetcher<GetMaintenanceResponse>(`/maintenance/${id}`);
}

function postMaintenance(
  bikeId: number,
  maintenanceType: MaintenanceType,
  comment: string
) {
  return postFetcher<PostMaintenanceResponse>(
    `/bike/${bikeId}/${maintenanceType}/call`,
    {
      comment
    }
  );
}

function postCancelMaintenance(maintenanceId: number) {
  return postFetcher<PostMaintenanceResponse>(
    `/maintenance/${maintenanceId}/stack`,
    { action: 10 }
  );
}

function postWaitMaintenance(maintenanceId: number, comment: string) {
  return postFetcher<PostMaintenanceResponse>(
    `/maintenance/${maintenanceId}/stack`,
    { action: 72, comment }
  );
}

const maintenanceApi = () => {
  return {
    getTable,
    get,
    postMaintenance,
    postCancelMaintenance,
    postWaitMaintenance
  };
};

export default maintenanceApi;
