import StatusLabel from "@components/MaintenanceControlBoard/StatusLabel";
import { BikeDetail, BikeStatus } from "types/bike.types";
import Box from "@mui/material/Box";

import MaintenanceCreateForm from "./MaintenanceCreateForm";
import StyledButtonBox from "./StyledButtonBox";
import StyledStatusButton from "./StyledStatusButton";

type Props = {
  bike: BikeDetail;
  handleSubmitCreate: (
    bikeId: number,
    maintenanceType: "rebalance" | "broken",
    comment: string
  ) => void;
  handleCancel: (
    bike: BikeDetail,
    maintenanceType: "rebalance" | "broken",
    id?: number
  ) => void;
  postCancelLoading: boolean;
};

const RebalancePanel = ({
  bike,
  handleSubmitCreate,
  handleCancel,
  postCancelLoading
}: Props) => {
  const REBALANCE_MAINTENANCE_TYPE = "rebalance";

  const transformLabelStatus = (status: BikeStatus) => {
    if (status === "BNB" || status === "LNB") {
      return "call";
    } else if (status === "BB" || status === "LB") {
      return "progress";
    } else {
      return "normal";
    }
  };

  const getRebalanceElement = (bike: BikeDetail) => {
    switch (bike.status) {
      case "BAV":
      case "LAV":
        return (
          <MaintenanceCreateForm
            bikeId={bike.id}
            maintenanceType={REBALANCE_MAINTENANCE_TYPE}
            handleSubmitCreate={handleSubmitCreate}
          />
        );

      case "BRD":
      case "LRD":
        return <Box>라이딩이 끝난 후에 관리할 수 있습니다.</Box>;

      case "BNB":
      case "LNB":
        return (
          <>
            <Box>{bike.last_maintenance.rebalance?.comment}</Box>
            <StyledButtonBox>
              <StyledStatusButton
                color="error"
                type="button"
                loading={postCancelLoading}
                onClick={() =>
                  handleCancel(
                    bike,
                    REBALANCE_MAINTENANCE_TYPE,
                    bike.last_maintenance.rebalance?.id
                  )
                }
              >
                취소
              </StyledStatusButton>
            </StyledButtonBox>
          </>
        );

      case "BNP":
      case "LNP":
        return <Box>고장 확인 필요 자전거는 재배치 등록이 불가합니다.</Box>;

      case "BB":
      case "LB":
        return <Box>{bike.last_maintenance.rebalance?.comment}</Box>;

      case "BP":
      case "LP":
        return <Box>수거한 자전거는 재배치 등록이 불가합니다.</Box>;
    }
  };
  return (
    <>
      <StatusLabel
        type={REBALANCE_MAINTENANCE_TYPE}
        status={transformLabelStatus(bike.status)}
      />
      {getRebalanceElement(bike)}
    </>
  );
};

export default RebalancePanel;
