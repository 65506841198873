import { BikeDetail, BikeIdle } from "types/bike.types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import SelectedBikeInfo from "./SelectedBikeInfo";
import StatusMaintenanceBoard from "./StatusMaintenanceBoard";

type Props = {
  bike: BikeDetail;
  bikeIdles?: BikeIdle[];
  setSelectedBike: (bike: any) => void;
  onActionSuccess?: () => void;
};

function MaintenanceControlBoard({
  bike,
  bikeIdles,
  setSelectedBike,
  onActionSuccess
}: Props) {
  return (
    <StyledBoardPaper elevation={2}>
      <SelectedBikeInfo bike={bike} bikeIdles={bikeIdles} />
      <StatusMaintenanceBoard
        bike={bike}
        setSelectedBike={setSelectedBike}
        onActionSuccess={onActionSuccess}
      />
    </StyledBoardPaper>
  );
}

const StyledBoardPaper = styled(Paper)(() => ({
  padding: "16px"
}));

export default MaintenanceControlBoard;
