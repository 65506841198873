import { useCallback, useState } from "react";

import maintenanceApi from "@network/maintenance/maintenanceApi";

const useCancelMaintenanceCreateBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const postCancelMaintenance = async (maintenanceId: number) => {
    try {
      setError(null);
      setIsLoading(true);
      return await maintenanceApi().postCancelMaintenance(maintenanceId);
    } catch (e: unknown) {
      if (e instanceof Error) setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    postCancelMaintenance: useCallback(postCancelMaintenance, []),
    isLoading,
    error
  };
};

export default useCancelMaintenanceCreateBaseQuery;
