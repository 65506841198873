import { getFetcher } from "@network/fetchers";
import { GetAnchorResponse } from "types/anchor.types";

function get() {
  return getFetcher<GetAnchorResponse>("/v1/franchise/anchor");
}

const anchorApi = () => {
  return {
    get
  };
};

export default anchorApi;
