import anchorApi from "@network/anchor/anchorApi";
import { selector } from "recoil";

export const anchorSelector = selector({
  key: "anchorSelector",
  get: async () => {
    const anchor = await anchorApi().get();
    return anchor;
  }
});
