import { useState, useCallback } from "react";

import bikeApi from "@network/bike/bikeApi";

const useGetBikeDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const getBikeDetail = async (bikeId: number) => {
    try {
      setError(null);
      setIsLoading(true);
      return await bikeApi().getBikeDetail(bikeId);
    } catch (e: unknown) {
      if (e instanceof Error) setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getBikeDetail: useCallback(getBikeDetail, []),
    isLoading,
    error
  };
};

export default useGetBikeDetail;
