const getBikeIcon = (status: string, vendor: number, isSelected?: boolean) => {
  let bikeType = "";

  switch (vendor) {
    case 2:
      bikeType = "bicycle_1"; // 네오
      break;
    case 3:
      bikeType = "bicycle_3"; // 플러스
      break;
    case 5:
      bikeType = "bicycle_5"; // 3.0
      break;
    case 4:
    case 1:
      bikeType = "kickboard"; // 세그웨이, 세그웨이 맥스
      break;
    default:
      // 클래식
      bikeType = "bicycle";
      break;
  }
  return `${
    process.env.NEXT_PUBLIC_CDN_DOMAIN
  }/assets/etc/${bikeType}/marker/${status}${
    isSelected ? "-selected" : ""
  }.png`;
};

export default getBikeIcon;
