import { useCallback, useState } from "react";

import maintenanceApi from "@network/maintenance/maintenanceApi";
import { MaintenanceType } from "types/maintenance.types";

const useMaintenanceCreateBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const postMaintenance = async (
    bikeId: number,
    maintenanceType: MaintenanceType,
    comment: string
  ) => {
    try {
      setError(null);
      setIsLoading(true);
      return await maintenanceApi().postMaintenance(
        bikeId,
        maintenanceType,
        comment
      );
    } catch (e: unknown) {
      if (e instanceof Error) setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    postMaintenance: useCallback(postMaintenance, []),
    isLoading,
    error
  };
};

export default useMaintenanceCreateBaseQuery;
