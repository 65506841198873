import StatusLabel from "@components/MaintenanceControlBoard/StatusLabel";
import Box from "@mui/material/Box";
import { BikeDetail } from "types/bike.types";

function BatteryPanel({ bike }: { bike: BikeDetail }) {
  return (
    <>
      <StatusLabel
        type="battery"
        status={bike.status.startsWith("L") ? "call" : "normal"}
      />
      <Box>{`${bike.leftover}%`}</Box>
    </>
  );
}

export default BatteryPanel;
