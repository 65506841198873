import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";

function MaintenanceEditForm({
  bikeId,
  maintenanceType,
  handleSubmitCreate
}: {
  bikeId: number;
  maintenanceType: "rebalance" | "broken";
  handleSubmitCreate: (
    bikeId: number,
    maintenanceType: "rebalance" | "broken",
    comment: string
  ) => void;
}) {
  const { handleSubmit, control } = useForm({
    defaultValues: { comment: "" }
  });

  const typeText = maintenanceType === "rebalance" ? "재배치" : "고장 확인";

  return (
    <>
      <form
        onSubmit={handleSubmit((data) =>
          handleSubmitCreate(bikeId, maintenanceType, data.comment)
        )}
        style={{ flexGrow: 1 }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                placeholder={`${typeText}요청 메시지`}
                fullWidth
              />
            )}
            name="comment"
            control={control}
          />
          <LoadingButton color="error" type="submit">
            등록
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default MaintenanceEditForm;
