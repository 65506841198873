function getIdleTime(idle: string) {
  const now = new Date().getTime();
  const idleTime = new Date(idle).getTime();
  const gapTime = now - idleTime;

  let minutes = Math.floor(gapTime / (60 * 1000));
  const hour = Math.floor(minutes / 60);

  minutes = minutes % 60;

  return `(${hour > 0 ? `${hour}시간` : ""} ${minutes}분 유휴)`;
}

export default getIdleTime;
