import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import useAlert from "@hooks/useAlert";
import { BikeDetail } from "@interfaces/bike.interface";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { BaseInput } from "shared-ui";
import * as yup from "yup";

import StyledButtonBox from "./StyledButtonBox";

const schema = yup.object({
  comment: yup.string()
});

type FormValues = {
  comment: string;
};
export default function BrokenPanelPairItem({
  isWaitForShipment,
  bike,
  postWaitLoading,
  handleWait
}: {
  isWaitForShipment: boolean;
  postWaitLoading: boolean;
  bike: BikeDetail;
  handleWait: (bike: BikeDetail, id?: number, comment: string) => void;
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<FormValues>({
    defaultValues: {
      comment: ""
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const { showAlert } = useAlert();
  const onSubmit = async (data: FormValues) => {
    await handleWait(bike, bike.last_maintenance.broken?.id, data.comment);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledWarpperBox>
        {isWaitForShipment ? (
          <StyledWarpperBox>
            <Box> {bike.last_maintenance.broken?.comment}</Box>
            <Box>
              수거한 기기입니다. 관리를 취소하려면 관리앱에서 강제 배치
              해주세요.
            </Box>

            <BaseInput
              control={control}
              name="comment"
              error={errors.comment}
              label="출고대기 코멘트"
              helperText="(선택) 수리한 내역 또는 특이사항을 입력해 주세요. 입력할 내용이 없으면 바로 출고대기버튼을 눌러주세요."
              fullWidth
              size="small"
            />

            <Box>
              <StyledButtonBox>
                {true && (
                  <SubmitButton loading={postWaitLoading} type="submit">
                    출고대기
                  </SubmitButton>
                )}
              </StyledButtonBox>
            </Box>
          </StyledWarpperBox>
        ) : (
          <Box>
            출고 대기중인 기기입니다. 관리앱에서 최종 배치 할 수 있습니다.
          </Box>
        )}
      </StyledWarpperBox>
    </form>
  );
}
const StyledWarpperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SubmitButton = styled(LoadingButton)`
  background-color: #c4c4c4 !important;
  color: #111;
  padding: 4px;
  margin-left: auto;
`;
