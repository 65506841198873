import BikeChip from "@components/BikeChip";
import bikeConstant from "@constants/bike";
import { BikeDetail, BikeIdle } from "types/bike.types";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import getAreaFromBike from "@utils/getAreaFromBike";
import getBikeIcon from "@utils/getBikeIcon";
import getIdleTime from "@utils/getIdleTime";
import { useRecoilValue } from "recoil";
import { anchorSelector } from "store/recoil/anchorRecoil";

type Props = {
  bike: BikeDetail;
  bikeIdles?: BikeIdle[];
};

function SelectedBikeInfo({ bike, bikeIdles }: Props) {
  const { areas } = useRecoilValue(anchorSelector);

  const getStatusInText = (bike: BikeDetail) => {
    let bikeStatusInText = `${bike.status} / `;
    if (
      (bike.status === "BP" || bike.status === "LP") &&
      bike.last_maintenance.broken?.stacks[0]?.type === 72
    ) {
      bikeStatusInText += "출고대기";
    } else {
      bikeConstant.statusList.forEach((status) => {
        if (status.value.includes(bike.status)) bikeStatusInText += status.text;
      });
    }

    return bikeStatusInText;
  };

  const getIdleTimeBySN = (sn: string) => {
    if (!bikeIdles) return;
    const filtered = bikeIdles.filter((idle) => idle.sn === sn);
    return filtered[0] && getIdleTime(filtered[0].timestamp);
  };

  const isPassDisplayIdleCondition = (status: string) => {
    return (
      status === "BAV" ||
      status === "LAV" ||
      status === "BNP" ||
      status === "LNP" ||
      status === "BNB" ||
      status === "LNB"
    );
  };

  return (
    <StyledBikeStatus>
      <BikeChip sn={bike.sn} />
      <span>
        <img
          src={getBikeIcon(bike.status, bike.vendor)}
          alt="선택된 기기 마커"
          width={21}
        />
      </span>
      <span>{getStatusInText(bike)}</span>
      {isPassDisplayIdleCondition(bike.status) && getIdleTimeBySN(bike.sn)}
      <span>{getAreaFromBike(areas, bike)}</span>
      {bike.is_missing && (
        <Chip label="분실 신고" variant="outlined" sx={{ ml: "auto" }} />
      )}
    </StyledBikeStatus>
  );
}

const StyledBikeStatus = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  marginBottom: "20px"
}));

export default SelectedBikeInfo;
