import bike from "@constants/bike";
import { MaintenanceType } from "types/maintenance.types";
import Box from "@mui/material/Box";

type Status = "call" | "normal" | "progress";

type Props = { type: MaintenanceType; status: Status };

const NORMAL_GREEN = "#27CE56";
const BATTERY_CALL_YELLOW = "#FEE147";

const REBALANCE_PROGRESS_GRAY = "#9E9E9E";
const REBALANCE_CALL_PINK = "#FF6CBB";

const BROKEN_CALL_RED = "#FF5353";
const BROKEN_PROGRESS_BLUE = "#32318B";

const StatusLabel = ({ type, status }: Props) => {
  const transformBgColor = (status: Status) => {
    if (status === "normal") {
      return NORMAL_GREEN;
    }
    if (type === "battery" && status === "call") {
      return BATTERY_CALL_YELLOW;
    }

    if (type === "rebalance" && status === "progress") {
      return REBALANCE_PROGRESS_GRAY;
    }

    if (type === "rebalance" && status === "call") {
      return REBALANCE_CALL_PINK;
    }

    if (type === "broken" && status === "call") {
      return BROKEN_CALL_RED;
    }

    if (type === "broken" && status === "progress") {
      return BROKEN_PROGRESS_BLUE;
    }
  };
  return (
    <Box sx={{ display: "flex", width: "100px", bgcolor: "#E9E9E9" }}>
      <Box
        sx={{
          width: "20px",
          bgcolor: transformBgColor(status)
        }}
      ></Box>
      <Box
        sx={{
          m: "auto",
          fontSize: "13px",
          p: "2px",
          width: 81,
          textAlign: "center"
        }}
      >
        {bike.maintenanceType[type]}
      </Box>
    </Box>
  );
};

export default StatusLabel;
