const bike = {
  vendor: [
    {
      value: 3,
      text: "플러스(24)"
    },
    { value: 2, text: "네오(24)" },
    {
      value: 0,
      text: "20인치"
    },
    {
      value: 4,
      text: "킥보드(맥스)"
    },
    {
      value: 5,
      text: "3.0"
    },
    { value: 1, text: "킥보드" },
    { value: -1, text: "없음", hidden: true }
  ],
  types: [
    {
      value: "universal",
      text: "전체"
    },
    {
      value: "bicycle",
      text: "자전거"
    },
    {
      value: "kickboard",
      text: "킥보드"
    }
  ],
  regions: [
    {
      value: 1000,
      text: "전국",
      types: ["bicycle", "kickboard", "universal"]
    },
    {
      value: 11,
      text: "서울",
      types: ["bicycle", "kickboard", "universal"],
      en: "seoul"
    },
    {
      value: 36,
      text: "세종",
      types: ["bicycle"],
      en: "sejong"
    },
    {
      value: 411,
      text: "경기남부",
      types: ["bicycle"],
      en: "samsung"
    },

    {
      value: 50,
      text: "제주",
      types: ["bicycle"],
      en: "jeju"
    }
  ],
  statusList: [
    {
      value: ["BAV"],
      text: "이용 가능"
    },
    {
      value: ["LAV"],
      text: "배터리 부족"
    },
    {
      value: ["BRD", "LRD"],
      text: "사용자 탑승중"
    },
    {
      value: ["BNB", "LNB"],
      text: "재배치 필요"
    },
    {
      value: ["BNP", "LNP"],
      text: "고장 확인 필요"
    },
    {
      value: ["BB", "LB"],
      text: "재배치중"
    },

    {
      value: ["BP", "LP"],
      text: "수리중"
    }
  ],
  mapFilterStatusList: [
    {
      value: ["BAV"],
      text: "이용 가능"
    },
    {
      value: ["LAV"],
      text: "배터리 부족"
    },
    {
      value: ["BNP", "LNP"],
      text: "고장 확인 필요"
    },
    {
      value: ["BNB", "LNB"],
      text: "재배치 필요"
    },
    {
      value: ["BP", "LP", "BB", "LB"],
      text: "관리중"
    },
    {
      value: ["BRD", "LRD"],
      text: "사용자 탑승중"
    }
  ],
  status: [
    "BAV",
    "BNB",
    "BNP",
    "BB",
    "BP",
    "BRD",
    "LAV",
    "LNB",
    "LNP",
    "LB",
    "LP",
    "LRD"
  ],
  statusColor: {
    BAV: ["#22bbff"],
    BNB: ["#ff00f6"],
    BNP: ["#ff1e00"],
    BB: ["#23326c"],
    BP: ["#23326c"],
    BRD: ["#60e817"],
    LAV: ["#ffcb00", "#22bbff"],
    LNB: ["#22bbff", "#ff00f6"],
    LNP: ["#22bbff", "#ff1e00"],
    LB: ["#22bbff", "#23326c"],
    LP: ["#22bbff", "#23326c"],
    LRD: ["#22bbff", "#60e817"]
  },
  statusGroup: {
    LOW: ["LAV"], //배터리부족
    BNB: ["BNB", "LNB"], //재배치필요
    BNP: ["BNP", "LNP"], //문제발생
    BP: ["BP", "LP", "BB", "LB"], //관리중
    BRD: ["BRD", "LRD"], //탑승중
    BAV: ["BAV"], //이용가능
    ON_FIELD: ["LAV", "BNB", "LNB", "BNP", "LNP", "BRD", "LRD", "BAV"],
    MANAGE: ["LAV", "LNP", "LNB", "LP", "LB", "LRD", "BNB", "LNB", "BNP", "LNP"] //
  },
  statusQuery: {
    NB: 0,
    NP: 1,
    B: 2,
    P: 3,
    RD: 4,
    AV: 5
  },
  maintenanceActions: [
    { value: 10, text: "취소" },
    { value: 16, text: "지역변경" },
    { value: 20, text: "현장조치완료" },
    { value: 30, text: "수거" },
    { value: 40, text: "위탁" },
    { value: 50, text: "위탁회수" },
    { value: 60, text: "수리 완료" },
    { value: 70, text: "배터리 충전완료" },
    { value: 72, text: "출고 대기" },
    { value: 75, text: "교체 후 배치" },
    { value: 80, text: "배치" }
  ],
  maintenanceType: {
    rebalance: "재배치",
    battery: "배터리",
    broken: "이슈"
  }
};

export default bike;
