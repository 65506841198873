import useCancelMaintenanceCreateBaseQuery from "@hooks/mutations/useCancelMaintenanceCreateBaseQuery";
import useMaintenanceCreateBaseQuery from "@hooks/mutations/useMaintenanceCreateBaseQuery";
import useWaitMaintenanceCreateBaseQuery from "@hooks/mutations/useWaitMaintenanceCreateBaseQuery";
import useGetBikeDetail from "@hooks/queries/useBikeDetailBaseQuery";
import useAlert from "@hooks/useAlert";
import useConfirm from "@hooks/useConfirm";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { BikeDetail } from "types/bike.types";

import BatteryPanel from "./BatteryPanel";
import BrokenPanel from "./BrokenPanel";
import RebalancePanel from "./RebalancePanel";

type StatusEditableProps = {
  bike: BikeDetail;
  setSelectedBike: (bike: BikeDetail) => void;
  onActionSuccess?: () => void;
};

function StatusMaintenanceBoard({
  bike,
  setSelectedBike,
  onActionSuccess
}: StatusEditableProps) {
  const { getBikeDetail } = useGetBikeDetail();
  const { postCancelMaintenance, isLoading: postCancelLoading } =
    useCancelMaintenanceCreateBaseQuery();
  const { postWaitMaintenance, isLoading: postWaitLoading } =
    useWaitMaintenanceCreateBaseQuery();

  const { postMaintenance } = useMaintenanceCreateBaseQuery();
  const { showConfirm } = useConfirm();
  const { showAlert } = useAlert();

  // 재배치, 이슈 - 등록
  const handleSubmitCreate = async (
    bikeId: number,
    maintenanceType: "rebalance" | "broken",
    comment: string
  ) => {
    const typeText = maintenanceType === "rebalance" ? "재배치" : "이슈";

    const confirmMessage = `${typeText} 필요 기기로 변경합니다 (${
      comment ?? "메시지 없음"
    })`;
    const isConfirmed = await showConfirm({ content: confirmMessage });
    if (isConfirmed) {
      await postMaintenance(bikeId, maintenanceType, comment);
      const bikeResponse = await getBikeDetail(bikeId);
      bikeResponse && setSelectedBike(bikeResponse);
      onActionSuccess && onActionSuccess();
    }
  };

  // 재배치, 이슈 - 취소
  const handleCancel = async (
    bike: BikeDetail,
    maintenanceType: "rebalance" | "broken",
    maintenanceId: number | undefined
  ) => {
    const typeText = maintenanceType === "rebalance" ? "재배치" : "고장 확인";

    if (maintenanceId) {
      const confirmMessage = `${typeText} 요청을 취소합니다`;
      const isConfirmed = await showConfirm({ content: confirmMessage });
      if (isConfirmed) {
        await postCancelMaintenance(maintenanceId);
        const bikeResponse = await getBikeDetail(bike.id);
        bikeResponse && setSelectedBike(bikeResponse);
        onActionSuccess && onActionSuccess();
      }
    }
  };

  // 이슈 - 출고대기 상태로 전환
  const handleWait = async (
    bike: BikeDetail,
    maintenanceId: number | undefined,
    comment: string
  ) => {
    if (maintenanceId) {
      const confirmMessage =
        "수리를 완료하고 출고 대기 상태로 변경합니다. 출고 대기는 취소할 수 없습니다.";

      const isConfirmed = await showConfirm({ content: confirmMessage });

      if (!isConfirmed) return;

      await postWaitMaintenance(maintenanceId, comment);
      const bikeResponse = await getBikeDetail(bike.id);
      showAlert({ content: "출고 대기 상태로 전환되었습니다." });

      bikeResponse && setSelectedBike(bikeResponse);
      onActionSuccess && onActionSuccess();
    }
  };

  return (
    <>
      <StyledStatusContainer>
        <StyledPannelContainer>
          <BatteryPanel bike={bike} />
        </StyledPannelContainer>
        <StyledPannelContainer>
          <RebalancePanel
            bike={bike}
            handleSubmitCreate={handleSubmitCreate}
            handleCancel={handleCancel}
            postCancelLoading={postCancelLoading}
          />
        </StyledPannelContainer>
        <StyledPannelContainer>
          <BrokenPanel
            bike={bike}
            handleSubmitCreate={handleSubmitCreate}
            handleCancel={handleCancel}
            handleWait={handleWait}
            postCancelLoading={postCancelLoading}
            postWaitLoading={postWaitLoading}
          />
        </StyledPannelContainer>
      </StyledStatusContainer>
    </>
  );
}

export default StatusMaintenanceBoard;

const StyledStatusContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px"
}));

const StyledPannelContainer = styled(Box)(() => ({
  display: "flex",
  flexGrow: 1,
  alignItems: "start",
  gap: "30px"
}));
